<template>
  <footer class="footer py-3">
    <div class="container-fluid text-center">
      &copy; 2021 - NIXAP DEVELOPMENT S.R.L.
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
