<template>
  <div>
    <div class="row-wp">
      <div class="left-bar">
        <OperatorSideBar/>
      </div>
      <div class="main-bar">
        <div class="order-bar py-2">
          <router-link :to="{ name: 'OperatorDriversMap' }" class="btn btn-outline-dark">
            Hartă șoferi
          </router-link>
          <router-link :to="{ name: 'OperatorOrderView', params: { id: 0 } }" class="btn btn-outline-dark ml-3">
            Formular comandă
          </router-link>
          <b-button class="pull-right" v-b-toggle.zone-stats>
            <b-icon icon="list"></b-icon>
            Statistică zone
          </b-button>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <b-sidebar v-model="isStatisticsSidebarOpen" right id="zone-stats" title="Statistică zone" bg-variant="dark" text-variant="light" backdrop>
      <div class="px-3 py-2">
        <PolygonsStatistics v-if="isStatisticsSidebarOpen"></PolygonsStatistics>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import OperatorSideBar from '@/components/dashboard/Operator/SideBar'
import PolygonsStatistics from '@/components/dashboard/PolygonsStatistics'

export default {
  name: 'OperatorDashboard',
  components: { PolygonsStatistics, OperatorSideBar },
  data () {
    return {
      googleLoaded: true,
      isStatisticsSidebarOpen: false
    }
  },
  mounted () {}
}
</script>

<style scoped>
  .btn.btn-outline-dark {
    outline: none !important;
    box-shadow: none;
  }
  .btn.btn-outline-dark.router-link-exact-active {
    color: #ffffff;
    background-color: #343a40 !important;
  }
</style>
