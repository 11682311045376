<template>
  <div id="app">
    <AppHeader v-if="$store.getters['auth/isUserLogged']"></AppHeader>
    <SiteHeader v-else></SiteHeader>
    <router-view/>
    <Footer v-if="$store.getters['auth/isUserLogged']"></Footer>
    <DispatcherEvents v-if="isDispatcherLoggedIn"></DispatcherEvents>
  </div>
</template>

<style>

</style>
<script>
import AppHeader from '@/components/common/AppHeader'
import Footer from '@/components/common/Footer'
import SiteHeader from '@/components/common/SiteHeader'
import DispatcherEvents from '@/components/common/DispatcherEvents'

export default {
  components: { DispatcherEvents, AppHeader, SiteHeader, Footer },
  computed: {
    isDispatcherLoggedIn () {
      return this.$store.getters['auth/isUserLogged'] && this.$store.getters['auth/loggedUser'].roles.includes('dispatcher')
    }
  }
}
</script>
