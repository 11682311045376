<template>
  <div v-if="$store.state.isGoogleApiLoaded">
    <OrderPlaceField
      :disabled="isFulfilledOrder"
      v-for="(orderPlace, index) in order.places"
      :order-place="orderPlace"
      :index="index"
      :is-last="order.places.length - 1 === index"
      :key="'opf-' + index"
    ></OrderPlaceField>
  </div>
</template>

<script>
import OrderPlaceField from '@/components/dashboard/Operator/OrderPlaceField'

export default {
  name: 'OrderPlaces',
  components: { OrderPlaceField },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    isFulfilledOrder () {
      return this.order.state !== 'new'
    }
  }
}
</script>

<style scoped>

</style>
