<template>
  <div class="px-2 py-3">
    <h2 v-if="order.id">Order #{{ order.id }}</h2>
    <div v-if="order.call && order.call.state !== 'ended'" class="alert alert-dark" style="max-width: 300px;">
      <b-button :disabled="callInUpdate" @click="holdCall" v-if="order.call.state === 'started'" size="sm" variant="warning">HOLD</b-button>
      <b-button :disabled="callInUpdate" @click="unholdCall" v-if="order.call.state === 'on_hold'" size="sm" variant="info">UNHOLD</b-button>
      <b-button :disabled="callInUpdate" @click="endCall" id="cancel-call" size="sm" variant="danger" class="ml-4">CANCEL</b-button>
    </div>
    <b-form>
      <b-row no-gutters>
        <b-col md="8" class="px-2">
          <b-row>
            <b-col md="4">
              <b-form-input
                ref="clientPhone"
                v-model="clientPhone"
                id="input-1"
                type="text"
                placeholder="Telefon"
                required
                autocomplete="off"
                :disabled="isFulfilledOrder"
              ></b-form-input>
            </b-col>
            <b-col md="8">
              <b-form-input
                ref="clientName"
                v-model="clientName"
                id="input-2"
                type="text"
                placeholder="Nume client"
                required
                autocomplete="off"
                :disabled="isFulfilledOrder"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="4">
              <b-form-select
                v-model="order.service_id"
                id="input-3"
                :disabled="isFulfilledOrder"
                v-if="Array.isArray(services)"
              >
                <b-form-select-option v-for="service in services" :value="service.id" :key="'so-' + service.id">
                  {{ service.name }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col md="8">
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <label class="m-0">
                        <input v-model="isCustomOrderTime" type="checkbox" aria-label="Is not now"> &nbsp; <b-icon icon="gear"></b-icon>
                      </label>
                    </b-input-group-prepend>
                    <b-form-datepicker :disabled="isFulfilledOrder || !isCustomOrderTime" id="input-datepicker" locale="ro" v-model="orderDate"></b-form-datepicker>
                  </b-input-group>
                </b-col>
                <b-col md="4">
                  <b-form-timepicker :disabled="isFulfilledOrder || !isCustomOrderTime" locale="ro" v-model="orderTime" show-seconds></b-form-timepicker>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md="12">
              <OrderPlaces :order="order"></OrderPlaces>
              <b-collapse class="mt-3 mb-3" id="preluare-detalii">
                <b-form-textarea
                  placeholder="Client comment"
                  v-model="clientComment"
                  :disabled="isFulfilledOrder"
                ></b-form-textarea>
              </b-collapse>
              <div class="clearfix"></div>

              <b-collapse class="mt-3" id="istoric-add">
                <b-card><b-form-select disabled :select-size="11"></b-form-select></b-card>
              </b-collapse>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="px-2">

        </b-col>
      </b-row>
      <div>
        <b-alert class="mt-3"
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
        >
          <p>Căutăm șofer disponibil, {{ dismissCountDown }} secunde...</p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>

        <b-button v-if="!isFulfilledOrder" @click="updateOrder()" variant="info" class="m-1">
          Plasează comandă
        </b-button>
      </div>

    </b-form>
    <b-button v-if="false" class="my-5" variant="outline-success" :to="{ name: 'OperatorOrderView', params: { id: 0 } }"><b-icon icon="plus"></b-icon> Comandă nouă</b-button>
  </div>
</template>

<script>
import OrderPlaces from '@/components/dashboard/Operator/OrderPlaces'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import config from '@/config'
Vue.use(VeeValidate, {
  fieldsBagName: '$fields'
})

export default {
  name: 'OrderForm',
  components: { OrderPlaces },
  data () {
    return {
      dismissSecs: 2,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      driverFinded: 0,
      isCustomOrderTime: false,
      callInUpdate: false,
      refreshInterval: null
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.refreshInterval = setInterval(() => {
      if (this.$store.getters['auth/loggedUser']) {
        this.order.dispatcher_id = this.$store.getters['auth/loggedUser'].id
        if (!this.isCustomOrderTime) {
          this.order.departure_time = this.$day().format('YYYY-MM-DD HH:mm:ss')
        }
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.refreshInterval)
  },
  computed: {
    clientPhone: {
      get () {
        return this.order.client ? this.order.client.phone : ''
      },
      set (value) {
        this.order.phone = value
      }
    },
    clientName: {
      get () {
        return this.order.client ? this.order.client.name : ''
      },
      set (value) {
        this.order.name = value
      }
    },
    orderDate: {
      get () {
        return this.isCustomOrderTime ? this.$day(this.order.created_at).format('YYYY-MM-DD') : this.$day().format('YYYY-MM-DD')
      },
      set (value) {
        const date = value ? this.$day(value) : this.$day()
        date.$H = parseInt(this.$day().format('HH'))
        date.$m = parseInt(this.$day().format('mm'))
        date.$s = parseInt(this.$day().format('ss'))
        console.log(date)
        this.order.created_at = date.format('YYYY-MM-DD HH:mm:ss')
        this.order.departure_time = date.format('YYYY-MM-DD HH:mm:ss')
        this.$forceUpdate()
      }
    },
    orderTime: {
      get () {
        return this.isCustomOrderTime ? this.$day(this.order.created_at).format('HH:mm:ss') : this.$day().format('HH:mm:ss')
      },
      set (value) {
        const parts = this.order.created_at.split(' ')
        this.order.created_at = parts[0] + ' ' + value
        this.order.departure_time = parts[0] + ' ' + value
      }
    },
    services () {
      return this.$store.getters['dispatcherDashboard/getServices']
    },
    isFulfilledOrder () {
      return this.order.state !== 'new'
    },
    clientComment: {
      get () {
        return this.order.places[0] && this.order.places[0].comment ? this.order.places[0].comment : null
      },
      set (value) {
        this.order.places[0].comment = value
      }
    }
  },
  methods: {
    updateOrder () {
      // eslint-disable-next-line eqeqeq
      const action = this.$route.params.id != '0' ? 'dispatcherDashboard/updateOrder' : 'dispatcherDashboard/createOrder'
      this.$store.dispatch(action)
        .then((response) => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: response.data.id })
          this.$toasted.success('Order created', {
            duration: 3000
          })
        })
        .catch((error) => {
          Object.entries(error.response.data.errors).forEach(([key, group]) => {
            group.forEach((message) => {
              this.$toasted.error(message, {
                duration: 5000
              })
            })
          })
        })
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      setTimeout(
        () => {
          this.driverFinded = 1
        }, this.dismissSecs + '000'
      )
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    holdCall () {
      this.callInUpdate = true
      this.$axios.patch(config.baseApiUrl + '/api/calls/hold/' + this.order.id)
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          this.$store.state.sip.calls[this.clientPhone].session.newSession.hold()
        })
        .catch(() => {})
        .then(() => {
          this.callInUpdate = false
        })
    },
    unholdCall () {
      this.callInUpdate = true
      this.$axios.patch(config.baseApiUrl + '/api/calls/unhold/' + this.order.id)
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          const oSipSessionCall = this.$store.state.sip.calls[this.clientPhone].session.newSession
          oSipSessionCall.bTransfering = false
          oSipSessionCall.resume()
        })
        .catch(() => {})
        .then(() => {
          this.callInUpdate = false
        })
    },
    endCall () {
      this.callInUpdate = true
      this.$axios.patch(config.baseApiUrl + '/api/calls/cancel/', {
        phone_number: this.order.call.phone
      })
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          this.$store.state.sip.calls[this.clientPhone].session.newSession.hangup()
        })
        .catch(() => {})
        .then(() => {
          this.callInUpdate = false
        })
    }
  }
}
</script>

<style scoped>

</style>
