<template>
  <div>
    <b-list-group>
      <b-list-group-item v-for="(item, index) in data" :key="'psi-' + index">
        <div class="clearfix">
          <div class="pull-left">{{ item.name }}</div>
          <div class="pull-right">
            {{ item.busyDrivers }} / {{ item.freeDrivers }}
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'PolygonsStatistics',
  data () {
    return {
      interval: null,
      data: []
    }
  },
  mounted () {
    this.getStatistics()
    this.interval = setInterval(() => {
      this.getStatistics()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    getStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/polygons/statistics')
        .then((response) => {
          this.data = response.data
        })
    }
  }
}
</script>

<style scoped>

</style>
