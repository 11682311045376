<template>
  <div>
    <OrderForm v-if="orderInView" :order="orderInView"></OrderForm>
    <div class="px-3">
      <OrderDriverSearch v-if="orderInView" :order="orderInView"></OrderDriverSearch>
    </div>
  </div>
</template>

<script>
import OrderForm from '@/components/dashboard/Operator/OrderForm'
import OrderDriverSearch from '@/components/dashboard/Operator/OrderDriverSearch'

export default {
  name: 'OrderView',
  components: {
    OrderForm: OrderForm,
    OrderDriverSearch: OrderDriverSearch
  },
  mounted () {
    this.$store.dispatch('dispatcherDashboard/getServices')
    // eslint-disable-next-line eqeqeq
    if (this.$route.params.id != '0') {
      this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.$route.params.id })
    } else {
      this.$store.commit('dispatcherDashboard/createNewOrder')
    }
  },
  computed: {
    orderInView () {
      return this.$store.getters['dispatcherDashboard/getOrderInView']
    }
  },
  watch: {
    '$route.params.id' () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id != '0') {
        this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.$route.params.id })
      } else {
        this.$store.commit('dispatcherDashboard/createNewOrder')
      }
    }
  }
}
</script>

<style scoped>

</style>
