<template>
  <vue-google-heatmap
    v-if="$store.state.isGoogleApiLoaded"
    :points="points"
    :lat="46.7784"
    :lng="23.6172"
    :initial-zoom="13"
    :options="{mapTypeControl: false}"
    width="100%"
    height="100vh"
  />
</template>

<script>
import VueGoogleHeatmap from 'vue2-google-heatmap'
import Vue from 'vue'
Vue.use(VueGoogleHeatmap)

export default {
  data () {
    return {
      props: [
        { radius: '20px' },
        { disableDefaultUI: true }
      ],
      points: [
        { lat: 46.683 + (Math.random() / 10000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.683 + (Math.random() / 10000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.683 + (Math.random() / 10000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7732 + (Math.random() / 1000), lng: 23.5922 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.683 + (Math.random() / 10000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.683 + (Math.random() / 10000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 100) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7635 + (Math.random() / 1000), lng: 23.643 + (Math.random() / 10000) },
        { lat: 46.7633 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7582 + (Math.random() / 1000), lng: 23.642 + (Math.random() / 10000) },
        { lat: 46.7795 + (Math.random() / 1000), lng: 23.612 + (Math.random() / 10000) },
        { lat: 46.7835 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7735 + (Math.random() / 1000), lng: 23.6427 + (Math.random() / 1000) },
        { lat: 46.7625 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7512 + (Math.random() / 1000), lng: 23.6422 + (Math.random() / 1000) },
        { lat: 46.7725 + (Math.random() / 1000), lng: 23.6127 + (Math.random() / 1000) },
        { lat: 46.7732 + (Math.random() / 1000), lng: 23.5922 + (Math.random() / 1000) }
      ]
    }
  }
}
</script>
