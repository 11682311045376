<template>
  <div>
    <b-jumbotron class="mb-0">
      <template #header>Bine ai venit!</template>

      <template #lead>
        Ești autorizat ca Super Admin, sesiunea ta va expira în 60 miunte. Acest
        lucru este pentru a evita accesul persoanelor neautorizate.
      </template>

      <hr class="my-4" />

      <p>Alege secțiunea dorită:</p>
      <b-button variant="light" to="/operator"
        ><b-icon icon="headphones"></b-icon> Panou operator</b-button
      >
      <b-button class="ml-2" variant="light" to="/settings/polygons"
        ><b-icon icon="bezier"></b-icon> Delimitare zone</b-button
      >
      <b-button class="ml-2" variant="light" to="/settings"
        ><b-icon icon="gear"></b-icon> Setări sistem</b-button
      >
    </b-jumbotron>

    <b-container fluid>
      <div class="d-flex">
        <b-col class="my-3" md="2">
          <b-card title="786">
            <b-card-text>Total șoferi</b-card-text>
            <b-button href="#" size="sm" variant="outline-dark"
              >Vezi mai mult</b-button
            >
          </b-card>
        </b-col>
        <b-col class="my-3" md="2">
          <b-card title="12786">
            <b-card-text>Total clienți</b-card-text>
            <b-button href="#" size="sm" variant="outline-dark"
              >Vezi mai mult</b-button
            >
          </b-card>
        </b-col>
        <b-col class="my-3" md="2">
          <b-card title="4594">
            <b-card-text>Comenzi azi</b-card-text>
            <b-button href="#" size="sm" variant="outline-dark"
              >Vezi mai mult</b-button
            >
          </b-card>
        </b-col>
        <b-col class="my-3" md="2">
          <b-card title="904594">
            <b-card-text>Total comenzi</b-card-text>
            <b-button href="#" size="sm" variant="outline-dark"
              >Vezi mai mult</b-button
            >
          </b-card>
        </b-col>
        <b-col class="my-3" md="2">
          <b-card title="0" border-variant="warning">
            <b-card-text>Anunțuri sistem</b-card-text>
            <b-button href="#" size="sm" variant="warning"
              >Vezi mai mult</b-button
            >
          </b-card>
        </b-col>
        <b-col class="my-3" md="2">
          <b-card title="0" border-variant="danger">
            <b-card-text>Incidente</b-card-text>
            <b-button href="#" size="sm" variant="danger"
              >Vezi mai mult</b-button
            >
          </b-card>
        </b-col>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'DispatcherDashboard',
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped>
ul.list-group > li > div {
  padding: 5px;
}
ul.list-group > li > div:nth-of-type(1) {
  flex: 1;
}
ul.list-group > li > div:nth-of-type(2) {
  font-size: 12px;
  flex: 5;
}
ul.list-group > li > div:nth-of-type(3) {
  flex: 1;
  text-align: right;
}
</style>
