import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import HomePage from '../views/Website/Home.vue'
import OperatorDashboard from '../views/Operator/Dashboard.vue'
import store from '@/store'
import Login from '@/views/Login'
import Logout from '@/components/common/Logout'
import DriversMap from '@/components/dashboard/Operator/DriversMap'
import OrderView from '@/components/dashboard/Operator/OrderView'

Vue.use(VueRouter)

var title = 'TAXI'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: title
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Home',
      authRequired: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      authRequired: true
    }
  },
  {
    path: '/operator',
    component: OperatorDashboard,
    meta: {
      title: 'Operator Dashboard',
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'OperatorDriversMap',
        component: DriversMap
      },
      {
        path: 'order/:id',
        name: 'OperatorOrderView',
        component: OrderView
      }
    ]
  },
  {
    path: '/users/:type',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user/:id/:role',
    name: 'UserEdit',
    component: () => import(/* webpackChunkName: "users" */ '@/components/users/UserEdit'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/orders/:type',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/cars',
    name: 'Cars',
    component: () => import(/* webpackChunkName: "cars" */ '@/views/Cars'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances',
    name: 'Financiar',
    component: () => import(/* webpackChunkName: "finances" */ '@/views/Finances'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/sold',
    name: 'Sold soferi',
    component: () => import(/* webpackChunkName: "sold" */ '@/components/finances/SoldSoferi'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/transfer',
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfer" */ '@/components/finances/Transfer'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/finances/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '@/components/finances/History'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/car/:id',
    name: 'CarEdit',
    component: () => import(/* webpackChunkName: "cars" */ '@/components/cars/CarEdit'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/components/settings/general/General'),
    meta: {
      title: 'Setări sistem',
      authRequired: true
    }
  },
  {
    path: '/settings/polygons',
    name: 'MapPolygons',
    component: () => import(/* webpackChunkName: "cars" */ '@/components/settings/map/MapPolygons'),
    meta: {
      title: 'Setări zone',
      authRequired: true
    }
  },
  {
    path: '/settings/account',
    name: 'AccountSettings',
    component: () => import(/* webpackChunkName: "account" */ '@/components/settings/account/AccountSettings'),
    meta: {
      authRequired: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title === undefined) {
    document.title = 'TAXI'
  } else {
    document.title = to.meta.title
  }
  if (to.meta.authRequired && !store.getters['auth/isUserLogged']) {
    next('/login')
  } else {
    next()
  }
})

export default router
