<template>
  <div>
    <b-list-group v-if="orders.length > 0">
      <b-list-group-item button v-for="(order, index) in orders"  v-bind:class="{ hasDriver: order.driver }" :key="'no-'+index" @click="$emit('order-click', order)">
        <router-link  :to="{ name: 'OperatorOrderView', params: { id: order.id } }">
          <div class="details">
            <strong>#{{ order.id }}</strong>
            <time>{{ order.created_at.split(' ')[1] }}</time>
            <span class="ml-3">{{ order.client.phone }}</span>
            <b-icon-telephone-inbound-fill v-if="order.call" class="text-success pull-right"></b-icon-telephone-inbound-fill>
            <b-icon-cursor-fill v-if="order.driver" class="text-light pull-right"></b-icon-cursor-fill>
          </div>
          <div v-if="order.places.length > 0" class="address">
            {{ order.places[0].name }}
          </div>
        </router-link>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="text-center">Nu sunt comenzi ...</div>
  </div>
</template>

<script>
export default {
  name: 'OrdersList',
  props: {
    orders: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
