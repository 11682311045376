<template>
  <div class="mt-2">
    <div class="d-flex orders-navigation">
      <button @click="ordersView = 'live'" class="btn btn-sm btn-primary">
        Live
        <span class="badge badge-light">6</span>
      </button>
    </div>
    <div class="SideBar">
      <LiveOrders v-if="ordersView === 'live'"></LiveOrders>
    </div>
  </div>
</template>

<script>

import LiveOrders from '@/components/dashboard/Operator/Orders/LiveOrders'

export default {
  name: 'SideBar',
  components: {
    LiveOrders
  },
  data () {
    return {
      ordersView: 'live'
    }
  }
}
</script>

<style scoped>
.orders-navigation {
  padding: 0 10px;
}
</style>
