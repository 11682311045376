<template>
  <div style="max-width: 500px;">
    <div v-if="order.state === 'created'">
      <b-card bg-variant="light" text-variant="dark" title="Cautam sofer">
        <b-progress :value="100" variant="info" :striped="true" :animated="true"></b-progress>
      </b-card>
    </div>
    <b-card v-else-if="driver" bg-variant="light" text-variant="dark" title="Șofer alocat">
      <b-card-text>
        <table class="table">
          <thead>
          <tr>
            <th v-if="driver.default_car">Indicativ</th>
            <th v-if="driver.default_car">Nr. înmatriculare</th>
            <th v-if="driver">Nume șofer</th>
            <th>Timp estimativ</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td v-if="driver.default_car">{{ driver.default_car.id }}</td>
            <td v-if="driver.default_car">{{ driver.default_car.number }}</td>
            <td v-if="driver">{{ driver.name }}</td>
            <td>5 minute</td>
          </tr>
          </tbody>
        </table>
      </b-card-text>
      <b-button-group size="sm" v-if="false">
        <b-button variant="success"><b-icon icon="check"></b-icon> Aprobă</b-button>
        <b-button variant="warning"><b-icon icon="arrow-clockwise"></b-icon> Caută din nou</b-button>
        <b-button variant="danger"><b-icon icon="x"></b-icon> Anulează</b-button>
      </b-button-group>
      <b-button class="pull-right" size="sm" variant="outline-success"><b-icon icon="geo"></b-icon> Localizare</b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'OrderDriverSearch',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    driver () {
      return this.order.driver
    }
  }
}
</script>

<style scoped>

</style>
