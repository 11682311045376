<template>
  <header class="header">
    <div class="container-fluid">
      <b-row align-v="center">
        <b-col cols="2">
          <router-link to="/dashboard" class="taxi-brand">TAXI NAPOCA</router-link>
        </b-col>
        <b-col  cols="12" md="auto">
          <b-nav>
            <b-nav-item to="/dashboard" active>Dashboard</b-nav-item>
            <b-nav-item-dropdown
              text="Utilizatori"
            >
              <b-dropdown-item to="/users/dispatcher">Operatori</b-dropdown-item>
              <b-dropdown-item to="/users/driver">Șoferi</b-dropdown-item>
              <b-dropdown-item to="/users/client">Clienți</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/cars">Transport</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/users/admin">Administratori</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              text="Financiar"
            >
              <b-dropdown-item to="/finances">General</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/finances/sold">Sold șoferi</b-dropdown-item>
              <b-dropdown-item to="/finances/transfer">Transfer balanță</b-dropdown-item>
              <b-dropdown-item to="/finances/history">Istoric</b-dropdown-item>
              <b-dropdown-item disabled to="#">Facturi</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/finances">Setări</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              text="Hărți"
            >
              <b-dropdown-item to="/settings/polygons">Delimitare zone</b-dropdown-item>
              <b-dropdown-item to="#">Localizare șoferi</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item to="#">Rapoarte</b-nav-item>
            <b-nav-item to="/settings">Setări sistem</b-nav-item>
          </b-nav>
        </b-col>
        <b-col>
          <b-dropdown class=" pull-right" id="top-profile" right text="Contul meu" size="sm" variant="success">
            <router-link to="/operator" class="dropdown-item">Panou operator</router-link>
            <router-link to="/settings/account" class="dropdown-item">Setări cont</router-link>
            <router-link to="/logout" class="dropdown-item">Logout</router-link>
          </b-dropdown>
          <b-button id="showNotifications" @click="show = !show" class="mr-1 pull-right" size="sm" variant="outline-dark">Notificări <b-badge variant="dark">0</b-badge></b-button>

          <b-tooltip target="top-phone" title="Nu ai niciun apel pierdut"></b-tooltip>
          <b-button class="mr-1 pull-right" size="sm" variant="outline-dark">
            {{ timeNow }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <audio id="jssip-audio"></audio>

        <b-popover custom-class="header-notifications" placement="bottomleft" :show.sync="show" no-fade target="showNotifications" triggers="click">
          <template #title>Notificări</template>
          <b-tabs pills card vertical>
            <b-tab title="Tab 1" active><b-card-text>Tab contents 1</b-card-text></b-tab>
            <b-tab title="Tab 2"><b-card-text>Tab contents 2</b-card-text></b-tab>
            <b-tab title="Tab 3"><b-card-text>Tab contents 3</b-card-text></b-tab>
          </b-tabs>

        </b-popover>
  </header>
</template>

<script>
import NewClientCallToast from './NewClientCallToast'

export default {
  name: 'AppHeader',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NewClientCallToast
  },
  data () {
    return {
      timeNow: '',
      show: false
    }
  },
  created () {
    setInterval(this.getClock, 1000)
  },
  methods: {
    getClock: function () {
      this.timeNow = this.$day().format('HH:mm:ss')
    }
  }
}
</script>

<style scoped>

</style>
