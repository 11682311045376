import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/config'
import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isGoogleApiLoaded: false
  },
  mutations: {
    initStore (state) {
      if (localStorage.getItem('loggedUser')) {
        state.auth.user = JSON.parse(localStorage.getItem('loggedUser'))
      }
      if (localStorage.getItem('apiToken')) {
        state.auth.apiToken = localStorage.getItem('apiToken')
      }
      if (localStorage.getItem('meta')) {
        state.auth.meta = JSON.parse(localStorage.getItem('meta'))
      }
      this._vm.$loadScript('https://maps.googleapis.com/maps/api/js?key=' + config.googleMapsApiKey + '&libraries=drawing,geometry,places,visualization')
        .then(() => {
          const interval = setInterval(() => {
            if (typeof window.google !== 'undefined') {
              clearInterval(interval)
              state.isGoogleApiLoaded = true
            }
          }, 100)
        })
    }
  },
  actions: {
  },
  modules: {
    auth: {
      namespaced: true,
      state: {
        user: null,
        apiToken: null,
        meta: null
      },
      getters: {
        isUserLogged (state) {
          return state.apiToken && state.user
        },
        loggedUser (state) {
          return state.user
        },
        apiToken (state) {
          return state.apiToken
        }
      },
      mutations: {
        setUserData (state, {
          user,
          token,
          meta
        }) {
          state.user = user
          state.apiToken = token
          localStorage.setItem('loggedUser', JSON.stringify(user))
          localStorage.setItem('apiToken', token)

          const metaItems = {}
          meta.forEach((item) => {
            metaItems[item.key] = item.value
          })
          state.meta = metaItems
          localStorage.setItem('meta', JSON.stringify(metaItems))
        },
        logout (state) {
          state.user = null
          state.apiToken = null
          state.meta = null
          localStorage.removeItem('loggedUser')
          localStorage.removeItem('apiToken')
          localStorage.removeItem('meta')
        }
      }
    },
    dispatcherDashboard: {
      namespaced: true,
      state: {
        ordersList: [],
        orderInView: null,
        services: []
      },
      getters: {
        getOrders (state) {
          return state.ordersList
        },
        getOrderInView (state) {
          return state.orderInView
        },
        getServices (state) {
          return state.services
        }
      },
      mutations: {
        setOrders (state, payload) {
          state.ordersList = payload.orders
        },
        setOrderInView (state, payload) {
          state.orderInView = payload.order
        },
        setServices (state, payload) {
          state.services = payload.services
        },
        addNewPlaceToOrder (state) {
          state.orderInView.places.push((() => {
            return {
              name: '',
              place_id: null,
              long: null,
              lat: null
            }
          })())
        },
        removePlaceFromOrder (state, payload) {
          state.orderInView.places.splice(payload.index, 1)
        },
        createNewOrder (state) {
          state.orderInView = (() => {
            return {
              departure_time: null,
              service_id: 1,
              phone: '',
              name: null,
              places: [
                {
                  name: '',
                  long: null,
                  lat: null,
                  place_id: null,
                  comment: null
                }
              ],
              options: [],
              state: 'new',
              dispatcher_id: null
            }
          })()
        }
      },
      actions: {
        getOrders (context, payload) {
          let url
          switch (payload.type) {
            case 'live':
              url = config.baseApiUrl + '/api/dispatcher-orders/live'
              break
          }
          this._vm.$axios.get(url)
            .then(response => {
              context.commit('setOrders', {
                orders: response.data
              })
            })
        },
        viewOrder (context, payload) {
          this._vm.$axios.get(config.baseApiUrl + '/api/orders/' + payload.orderId)
            .then(response => {
              context.commit('setOrderInView', {
                order: response.data
              })
            })
        },
        getServices (context) {
          this._vm.$axios.get(config.baseApiUrl + '/api/services')
            .then(response => {
              context.commit('setServices', {
                services: response.data.data
              })
            })
        },
        createOrder (context) {
          return this._vm.$axios.post(config.baseApiUrl + '/api/orders/', context.state.orderInView)
        },
        updateOrder (context) {
          return this._vm.$axios.patch(config.baseApiUrl + '/api/orders/' + context.state.orderInView.id, context.state.orderInView)
        },
        acceptCall (context, payload) {
          return this._vm.$axios.post(config.baseApiUrl + '/api/calls/accept', {
            phone_number: payload.phoneNumber
          })
        }
      }
    },
    sip: {
      namespaced: true,
      state: {
        isSipConnected: false,
        calls: {}
      },
      mutations: {
        addCall (state, payload) {
          Vue.set(state.calls, payload.phone, {
            session: payload.session,
            orderId: null
          })
        },
        removeCall (state, payload) {
          delete state.calls[payload.phone]
        }
      },
      actions: {
        callEnded (context, payload) {
          return this._vm.$axios.patch(config.baseApiUrl + '/api/calls/cancel', {
            phone_number: payload.phoneNumber
          })
        }
      }
    }
  }
})
