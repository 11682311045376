<template>
  <div>
    <audio id="sip-audio" autoplay="autoplay"></audio>
  </div>
</template>

<script>
// import JsSip from '@/services/jsSip'
import NewClientCallToast from '@/components/common/NewClientCallToast'
import config from '@/config'
import store from '@/store'
import { format as formatPhone } from '@/services/sipPhoneFormatter'
import { data as bip } from '@/assets/audio/call'

export default {
  name: 'DispatcherEvents',
  mounted () {
    this.$connect(config.wsUrl + '?token=' + store.getters['auth/apiToken'])
    this.$options.sockets.onmessage = (data) => {
      const message = JSON.parse(data.data)
      this.$eventBus.$emit('ws_' + message.event, message.payload)
    }

    this.$eventBus.$on('new_client_call', (data) => {
      this.showNewCallToast(data)
    })

    this.initSipml()
  },
  beforeDestroy () {
    this.$disconnect()
    this.$eventBus.$off('new_client_call')
  },
  data () {
    return {
      sipStack: null
    }
  },
  methods: {
    initSipml () {
      if (store.state.auth.meta && store.state.auth.meta.sipUrl) {
        let impi
        try {
          impi = store.state.auth.meta.sipUrl.split('@')[0].split(':')[1]
        } catch (e) {
          impi = null
        }
        // eslint-disable-next-line no-undef
        SIPml.setDebugLevel('error')
        // eslint-disable-next-line no-undef
        this.sipStack = new SIPml.Stack({
          realm: 'voip-demo.it4all.ro',
          impi: impi,
          impu: store.state.auth.meta.sipUrl,
          password: store.state.auth.meta.sipPassword,
          display_name: store.state.auth.user.name,
          websocket_proxy_url: store.state.auth.meta.sipWs,
          enable_rtcweb_breaker: true,
          events_listener: {
            events: '*',
            listener: this.sipStackEventsListener
          }
        })
        this.sipStack.start()
      } else {
        this.$bvToast.toast('Please check your SIP credentials', {
          title: 'SIP is disabled',
          variant: 'danger',
          noAutoHide: true
        })
      }
    },
    sipStackEventsListener (event) {
      switch (event.type) {
        case 'started':
          this.sipLogin(event)
          break
        case 'connected':
          this.sipConnected(event)
          break
        case 'i_new_call':
          this.sipNewCall(event)
          break
        case 'failed_to_start':
          this.sipConnectFailed(event)
          break
        default:
          console.log('sip_stack_event', event)
      }
    },
    sipSessionEventsListener (event) {
      switch (event.type) {
        case 'terminated':
          this.sipCallEnded(event)
          break
        default:
          console.log('session', event)
      }
    },
    sipActiveSessionListener (event) {
      switch (event.type) {
        case 'terminated':
          this.sipCallEnded(event)
          break
        case 'm_stream_audio_remote_added':
          document.getElementById('sip-audio').play()
          break
        default:
          console.log('session', event)
      }
    },
    sipLogin () {
      this.sipStack.newSession('register').register()
    },
    sipConnected () {
      this.$store.state.sip.isSipConnected = true
    },
    sipConnectFailed () {
      this.$bvToast.toast('Can not connect to SIP server. Please check your network connection or SIP credentials', {
        title: 'SIP is disabled',
        variant: 'danger',
        noAutoHide: true
      })
    },
    sipNewCall (event) {
      event.newSession.setConfiguration({
        events_listener: { events: '*', listener: this.sipSessionEventsListener }
      })
      this.notifyAboutNewCall(formatPhone(event.o_event.o_session.o_uri_from.s_user_name), event)
    },
    sipAcceptCall (e) {
      e.newSession.accept({
        sip_caps: [
          { name: '+g.oma.sip-im' }
        ],
        audio_remote: document.getElementById('sip-audio'),
        events_listener: {
          events: '*',
          listener: this.sipActiveSessionListener
        }
      })
      setTimeout(() => {
        document.getElementById('sip-audio').play()
      }, 500)
    },
    sipCallEnded (e) {
      this.cancelCall(formatPhone(e.o_event.o_session.o_uri_from.s_user_name))
    },
    notifyAboutNewCall (phoneNumber, callEvent) {
      this.$bvToast.hide('new-call-toast-' + phoneNumber)

      this.$store.commit('sip/addCall', {
        phone: phoneNumber,
        session: callEvent
      })

      this.$eventBus.$emit('new_client_call', {
        phoneNumber: phoneNumber,
        callSession: callEvent,
        orderId: null
      })

      const audio = new window.Audio(bip)
      audio.play()
    },
    cancelCall (phoneNumber) {
      const data = this.$store.state.sip.calls[phoneNumber]
      console.log(data)
      this.$store.commit('sip/removeCall', {
        phone: phoneNumber
      })
      this.$store.dispatch('sip/callEnded', {
        phoneNumber: phoneNumber
      })
        .then(() => {
          const btn = document.getElementById('cancel-call')
          if (btn) {
            btn.click()
          }
        })
      this.$bvToast.hide('new-call-toast-' + phoneNumber)
    },
    showNewCallToast (data) {
      const toastId = 'new-call-toast-' + data.phoneNumber
      const self = this
      const comp = this.$createElement(NewClientCallToast, {
        props: {
          phoneNumber: data.phoneNumber
        },
        on: {
          'close' () {
            self.$bvToast.hide(toastId)
          },
          'accept-call' (payload) {
            self.$store.dispatch('dispatcherDashboard/acceptCall', payload)
              .then((response) => {
                self.sipAcceptCall(self.$store.state.sip.calls[payload.phoneNumber].session)
                self.$bvToast.hide(toastId)
                self.$router.push({ name: 'OperatorOrderView', params: { id: response.data.id } })
              })
          }
        }
      })
      this.$bvToast.toast([comp], {
        id: toastId,
        title: 'New client call',
        solid: true,
        variant: 'info',
        appendToast: true,
        noCloseButton: true,
        noAutoHide: true
      })
    }
  }
}
</script>

<style scoped>

</style>
