/**
 * @param {string} phoneNumber
 * @returns {string}
 */
function format (phoneNumber) {
  if (phoneNumber.charAt(0) === '0' && phoneNumber.charAt(1) === '0') {
    // international phone format
    phoneNumber = '+' + parseInt(phoneNumber)
  } else {
    // RO phone format
    phoneNumber = '+4' + phoneNumber
  }
  return phoneNumber
}

export {
  format
}
