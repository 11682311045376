<template>
  <header class="header">
    <div class="container-fluid">
    <b-row align-v="center">
      <b-col cols="2">
        <router-link to="/" class="taxi-brand">TAXI BRASOV</router-link>
      </b-col>
      <b-col  cols="12" md="auto">
        <b-nav>
          <b-nav-item to="/" active>Home</b-nav-item>
          <b-nav-item to="#">Despre</b-nav-item>
          <b-nav-item to="#">Contact</b-nav-item>
        </b-nav>
      </b-col>
      <b-col>
        <b-button variant="outline-success" size="sm" to="/login" class="pull-right"><b-icon icon="person"></b-icon> Intră în cont</b-button>
      </b-col>
    </b-row>
  </div>
  </header>
</template>

<script>

export default {
  name: 'AppHeader',
  data () {
    return {
      timeNow: ''
    }
  },
  created () {
    setInterval(this.getClock, 1000)
  },
  methods: {
    getClock: function () {
      this.timeNow = this.$day().format('HH:mm:ss')
    }
  }
}
</script>

<style scoped>

</style>
