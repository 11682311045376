<template>
  <div>
    <div v-if="$store.state.isGoogleApiLoaded">
      <GmapMap
        v-if="driversLocations"
        ref="map"
        :center="{lat:46.7735964, lng:23.6127841}"
        :zoom="14"
        :options="MapStyle"
        style="width: 100%; height: calc(100vh - 96px)"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in driversLocations"
          :position="{lat: m.lat, lng: m.long}"
          :clickable="true"
          :draggable="true"
          :label="{color:'#fff', fontSize:'10px',fontWeight:'bold', text: '42' + index}"
          @click="center=m.position"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import mapIco from '@/assets/img/location.png'
import config from '@/config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'DriversMap',
  data () {
    return {
      driversLocations: [],
      icon: {
        url: mapIco,
        scaledSize: { width: 40, height: 40 },
        labelOrigin: { x: 16, y: -10 }
      },
      selected: null,
      optionsSaved: [
        { value: null, text: 'Barbu Lautaru 12' },
        { value: 'a', text: 'Aleea Florilor 7B' }
      ],
      MapStyle: {
        mapTypeControl: false,
        streetViewControl: false
      },
      getDriverPositionsInterval: null
    }
  },
  mounted () {
    this.getDriverPositions()
    this.getDriverPositionsInterval = setInterval(this.getDriverPositions, 10000)
  },
  beforeDestroy () {
    clearInterval(this.getDriverPositionsInterval)
  },
  methods: {
    getDriverPositions () {
      this.$axios.get(config.baseApiUrl + '/api/drivers-positions')
        .then(response => {
          this.driversLocations = response.data
        })
    }
  }
}
</script>

<style scoped>

</style>
