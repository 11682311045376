<template>
  <b-input-group class="mb-3">
    <template #prepend>
      <b-input-group-text>
        <strong class="text-dark">
          {{ fieldLetterPrefix }}
        </strong>
      </b-input-group-text>
    </template>
    <b-form-input :disabled="disabled" ref="input" :placeholder="placeholder" v-model="orderPlace.name"></b-form-input>
    <b-input-group-append v-if="index === 0">
      <b-button v-b-toggle.istoric-add variant="outline-success"><b-icon icon="clock-history"></b-icon> <b-badge variant="light">1</b-badge></b-button>
      <b-button v-b-toggle.preluare-detalii variant="outline-success"><b-icon icon="chat-left-text"></b-icon></b-button>
    </b-input-group-append>
    <b-input-group-append v-if="index !== 0">
      <b-button @click="removePlace" v-b-toggle.preluare-detalii variant="outline-danger"><b-icon icon="trash"></b-icon></b-button>
    </b-input-group-append>
    <b-input-group-append>
      <b-button :disabled="disabled" @click="addNewPlace" v-if="isLast" v-b-toggle.preluare-detalii variant="outline-success">
        <b-icon icon="plus"></b-icon>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>

export default {
  name: 'OrderPlaceField',
  components: {},
  props: {
    orderPlace: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isLast: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mounted () {
    const options = {
      componentRestrictions: { country: 'ro' },
      fields: ['formatted_address', 'geometry', 'place_id'],
      strictBounds: true
    }
    const autocomplete = new window.google.maps.places.Autocomplete(this.$refs.input.$el, options)
    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.placeChanged(autocomplete.getPlace())
    })
  },
  computed: {
    fieldLetterPrefix () {
      return 'ABCDEFGHIGKLMNOPQRSTUVWXYZ'[this.index]
    },
    placeholder () {
      return this.index === 0 ? 'Adresa preluare' : 'Destinatie'
    },
    isSdkLoaded () {
      return this.$store.state.isGoogleApiLoaded
    }
  },
  methods: {
    placeChanged (place) {
      this.orderPlace.name = place.formatted_address
      this.orderPlace.lat = place.geometry.location.lat()
      this.orderPlace.long = place.geometry.location.lng()
      this.orderPlace.place_id = place.place_id
    },
    addNewPlace () {
      this.$store.commit('dispatcherDashboard/addNewPlaceToOrder')
    },
    removePlace () {
      this.$store.commit('dispatcherDashboard/removePlaceFromOrder', {
        index: this.index
      })
    }
  }
}
</script>

<style scoped>

</style>
